import ParentProductModel from '../../v5/Core/Products/Models/ProductModel';
class ProductsModel extends ParentProductModel {
  set KodVnutrennii(value) { this._internalVariables.refNum = value; }
  set Nomenklatura(value) {
    const tmp = value.split("@");
    const name = tmp.shift();
    if (tmp.length > 0) {
      const last = tmp.pop();
      let sizesArray = last.split("(");
      sizesArray = sizesArray.pop();
      sizesArray = sizesArray.split(")");
      sizesArray = sizesArray[0];
      sizesArray = sizesArray.split(",");
      this._internalVariables.sizesArray = {};
      sizesArray.map(size => {
        const s = size.trim();
        this._internalVariables.sizesArray[size] = 0;
      });
    }
    this.name = name;
  }
  set Harakteristika(value) { this._internalVariables.Harakteristika = value; }
  set Artikul(value) {
    const tmp = value.split("@");
    this._internalVariables.article = tmp[0];
  }
  set Brend(value) { this._internalVariables.brand = value; }
  set CvetHar(value) { this._internalVariables.CvetHar = value; }
  set RazmerHar(value) {
    this._internalVariables.RazmerHar = value;
    this._internalVariables.sizesArray[value] = 0;
  }
  set Pol(value) { this._internalVariables.Pol = value; }
  set KlassifikaciyaModeli(value) { this._internalVariables.KlassifikaciyaModeli = value; }
  set GodIzgotovleniya(value) { this._internalVariables.GodIzgotovleniya = value; }
  set SezonPrimeneniya(value) { this._internalVariables.SezonPrimeneniya = value; }
  set StranaIzgotovitel(value) { this._internalVariables.StranaIzgotovitel = value; }
  set Material(value) { this._internalVariables.Material = value; }
  set Konstrukciya(value) { this._internalVariables.Konstrukciya = value; }
  set Uteplitel(value) { this._internalVariables.Uteplitel = value; }
  set GruppaTovara(value) { this._internalVariables.GruppaTovara = value; }
  set Kollekciya(value) { this._internalVariables.Kollekciya = value; }
  set Osobennost(value) { this._internalVariables.Osobennost = value; }
  set SostavTkani(value) { this._internalVariables.SostavTkani = value; }
  set EtoNabor(value) { this._internalVariables.EtoNabor = +value === 1; }
  set KolichestvoVNabore(value) {
    this.orderQuantity = value;
  }
  set MinZakazEdinitsTovarov(value) { this._internalVariables.MinZakazEdinitsTovarov = +value; }
  set KodOsnovnogoKomponentaVnutrennii(value) { this._internalVariables.KodOsnovnogoKomponentaVnutrennii = value; }
  set NomenklaturaOsnovnogoKomponenta(value) { this._internalVariables.NomenklaturaOsnovnogoKomponenta = value; }
  set HarakteristikaOsnovnogoKomponenta(value) { this._internalVariables.HarakteristikaOsnovnogoKomponenta = value; }
  set TGroup(value) { this._internalVariables.TGroup = value; }
  set KolichestvoOstatok(value) { this._internalVariables.inStock = value; }
  set Cena(value) { this._internalVariables.priceCurrent = +value; }
  set CenaStaraya(value) { this._internalVariables.priceRegular = +value; }
  set AllowToSale(value) { this._internalVariables.allowedToSale = value; }
  set MinZakaz(value) { this._internalVariables.MinZakaz = value; }
  set Novinka(value) { this._internalVariables.Novinka = value; }
  set VozrastnayaGruppa(value) { this._internalVariables.VozrastnayaGruppa = value; }
  set RazmerGruppa(value) { this._internalVariables.RazmerGruppa = value; }
  set RazmerPostavshikaHar(value) { this._internalVariables.RazmerPostavshikaHar = value; }
  set updated(value) { this._internalVariables.updated = value; }
  set _fullcount(value) { this._internalVariables._fullcount = value; }
  set _ostcount(value) { this._internalVariables._ostcount = value; }
  set _parent(value) { this.parent = value; }
  set imagesCount(value) { this._internalVariables.imagesCount = value; }
  set PodZakaz(value) { this._internalVariables.PodZakaz = value; }
  set categoryId(value) { this._internalVariables.categoryId = value; }
  set PlanovayaDataZakaza(value) { this._internalVariables.PlanovayaDataZakaza = value; }
  set PlanovayaDataPostavki(value) { this._internalVariables.PlanovayaDataPostavki = value; }
  set KodNomenklaturi(value) { this._internalVariables.KodNomenklaturi = value; }
  set Barcode(value) { this._internalVariables.barcode = value; }
  set VozrastSpisok(value) { this._internalVariables.VozrastSpisok = value; }
  set inLinks(value) { this._internalVariables.inLinks = value; }
  set PriceRetailRecommend(value) { this._internalVariables.priceRecommend = value; }
  set reserved(value) { this._internalVariables.reserved = value; }
  set description(value) { this._internalVariables.description = value; }
  set reservettl(value) { this._internalVariables.reservettl = value; }
  set sizesArray(value) { this._internalVariables.sizesArray = value; }
  set store(value) { this._internalVariables.store = value; }
  set imagesList(value) { this._internalVariables.images = value; }

  get KodVnutrennii() { return this._internalVariables.refNum; }
  get Nomenklatura() { return this._internalVariables.name; }
  get Harakteristika() { return this._internalVariables.Harakteristika; }
  get Artikul() { return this._internalVariables.article; }
  get Brend() { return this._internalVariables.Brend; }
  get CvetHar() { return this._internalVariables.CvetHar; }
  get RazmerHar() { return this._internalVariables.RazmerHar; }
  get Pol() { return this._internalVariables.Pol; }
  get KlassifikaciyaModeli() { return this._internalVariables.KlassifikaciyaModeli; }
  get GodIzgotovleniya() { return this._internalVariables.GodIzgotovleniya; }
  get SezonPrimeneniya() { return this._internalVariables.SezonPrimeneniya; }
  get StranaIzgotovitel() { return this._internalVariables.StranaIzgotovitel; }
  get Material() { return this._internalVariables.Material; }
  get Konstrukciya() { return this._internalVariables.Konstrukciya; }
  get Uteplitel() { return this._internalVariables.Uteplitel; }
  get GruppaTovara() { return this._internalVariables.GruppaTovara; }
  get Kollekciya() { return this._internalVariables.Kollekciya; }
  get Osobennost() { return this._internalVariables.Osobennost; }
  get SostavTkani() { return this._internalVariables.SostavTkani; }
  get EtoNabor() { return this._internalVariables.EtoNabor; }
  get KolichestvoVNabore() { return this._internalVariables.KolichestvoVNabore; }
  get MinZakazEdinitsTovarov() { return this._internalVariables.MinZakazEdinitsTovarov; }
  get KodOsnovnogoKomponentaVnutrennii() { return this._internalVariables.KodOsnovnogoKomponentaVnutrennii; }
  get NomenklaturaOsnovnogoKomponenta() { return this._internalVariables.NomenklaturaOsnovnogoKomponenta; }
  get HarakteristikaOsnovnogoKomponenta() { return this._internalVariables.HarakteristikaOsnovnogoKomponenta; }
  get TGroup() { return this._internalVariables.TGroup; }
  get KolichestvoOstatok() { return this._internalVariables.inStock; }
  get Cena() { return this._internalVariables.priceCurrent; }
  get CenaStaraya() { return this._internalVariables.priceRegular; }
  get AllowToSale() { return this._internalVariables.allowedToSale; }
  get MinZakaz() { return this._internalVariables.MinZakaz; }
  get Novinka() { return this._internalVariables.Novinka; }
  get VozrastnayaGruppa() { return this._internalVariables.VozrastnayaGruppa; }
  get RazmerGruppa() { return this._internalVariables.RazmerGruppa; }
  get RazmerPostavshikaHar() { return this._internalVariables.RazmerPostavshikaHar; }
  get updated() { return this._internalVariables.updated; }
  get _fullcount() { return this._internalVariables._fullcount; }
  get _ostcount() { return this._internalVariables._ostcount; }
  get _parent() { return this._internalVariables.parent; }
  get imagesCount() { return this._internalVariables.imagesCount; }
  get PodZakaz() { return this._internalVariables.categoryId; }
  get categoryId() { return this._internalVariables.refNum; }
  get PlanovayaDataZakaza() { return this._internalVariables.PlanovayaDataZakaza; }
  get PlanovayaDataPostavki() { return this._internalVariables.PlanovayaDataPostavki; }
  get KodNomenklaturi() { return this._internalVariables.KodNomenklaturi; }
  get Barcode() { return this._internalVariables.barcode; }
  get VozrastSpisok() { return this._internalVariables.VozrastSpisok; }
  get inLinks() { return this._internalVariables.inLinks; }
  get PriceRetailRecommend() { return this._internalVariables.priceRecommend; }
  get reserved() { return this._internalVariables.reserved; }
  get description() { return this._internalVariables.description; }
  get reservettl() { return this._internalVariables.reservettl; }
  get sizesArray() { return this._internalVariables.sizesArray; }
  get store() { return this._internalVariables.store; }
  get imagesList() { return this._internalVariables.images; }

  constructor() {
    super();
    this._internalVariables.sizesArray = {};
    this._internalVariables.EtoNabor = false;
  }

  normalize() {
    if (this.EtoNabor) {
      // Object.keys(this.sizesArray).map(size => this.sizesArray[size] = this.inStock)
    }
  }

  updateProduct(product) {
    super.updateProduct(product);
  }

  addSubProduct(product) {
    super.addSubProduct(product);
    this.sizesArray[product.RazmerHar] = product.inStock;
  }
}

export default ProductsModel;