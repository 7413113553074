export default class Product {
  // ---- Product properties
  AllowToSale = null;
  Artikul = null;
  Barcode = null;
  Brand = null;
  Price = null;
  PriceOld = null;
  Color = null;
  Pack = 1;
  ModelYear = null;
  Group = null;
  RefNum = null;
  PacksInStore = 0;
  ItemsInPack = 0;
  MinOrderQuantity = 0;
  ProductName = null;
  // Этот товар является новинкой (недавно поступил)
  NewItem = false;
  PreOrder = false;
  PreOrderLastDate = null;
  PreOrderDeliveryDate = null;
  Material = null;

  // Harakteristika = null;
  // HarakteristikaOsnovnogoKomponenta = null;
  // KlassifikaciyaModeli = null;
  // KodOsnovnogoKomponentaVnutrennii = null;
  // Kollekciya = null;
  // Konstrukciya = null;
  // MinZakazEdinitsTovarov = null;
  // Nomenklatura = null;
  // NomenklaturaOsnovnogoKomponenta = null;
  // Osobennost = null;
  // Pol = null;
  // RazmerGruppa = null;
  // RazmerHar = null;
  // RazmerPostavshikaHar = null;
  // SezonPrimeneniya = null;
  // SostavTkani = null;
  // StranaIzgotovitel = null;
  // TGroup = null;
  // Uteplitel = null;
  // VozrastnayaGruppa = null;
  // categoryId = null;
  imagesCount = 0;
  imagesList = [];
  sizes = [];
  sizes_array = [];
  store = {}

  updated = null;
  _fullcount = 0;
  _ostcount = 0;

  // ---- Calculated
  inCart = {}
  linkSize = {}

  // _parent = 0;
  packsInCart = 0;
  minOrderInCart = 0;
  singlesInCart = {};

  // ---- Checks
  allowOrderPack() {};
  allowOrderMinOrder() {};
  allowOrderSingle() {};
  // ---- Cart manipulation
  addPackToCart() {};
  addMinOrderToCart() {};
  addSingleToCart() {};
  delPackFromCart() {};
  delMinOrderFromCart() {};
  delSingleFromCart() {};
  removePackFromCart() {};
  removeMinOrderFromCart() {};
  removeSingleFromCart() {};
  getID() {
    return this.RefNum;
  }
  // ---- Product changes
  createFromJSON(data) {
  // createFromJSON = (data) => {
    this.AllowToSale = parseInt(data.AllowToSale,10);
    this.Artikul = data.Artikul;
    this.Barcode = data.Barcode;
    this.Brand = data.Brend;
    this.Price = parseInt(data.Cena,10);
    this.PriceOld = parseInt(data.CenaStaraya,10);
    this.Color = data.CvetHar;
    this.Pack = parseInt(data.EtoNabor,10);
    this.ModelYear = parseInt(data.GodIzgotovleniya,10);
    this.Group = data.GruppaTovara;
    this.RefNum = parseInt(data.KodVnutrennii,10);
    this.PacksInStore = parseInt(data.KolichestvoOstatok,10);
    this.ItemsInPack = parseInt(data.KolichestvoVNabore,10);
    this.MinOrderQuantity = parseInt(data.MinZakaz,10);
    if(
      this.MinOrderQuantity===0 ||
      isNaN(this.MinOrderQuantity)
    ) {
      this.MinOrderQuantity = this.ItemsInPack;
    }
    var s = data.Nomenklatura.split("@");
    this.ProductName = s[0].trim();
    this.NewItem = parseInt(data.Novinka,10)===1?true:false;
    this.PreOrder = parseInt(data.PodZakaz,10)===1?true:false;
    this.PreOrderLastDate = data.PlanovayaDataZakaza;
    this.PreOrderDeliveryDate = data.PlanovayaDataPostavki;
    this.Material = data.Material;
    this.imagesCount = parseInt(data.imagesCount,10);
    this.imagesList = data.imagesList;
    this.sizes = data.sizes;
    this.sizes_array = data.sizes_array;
    this.updated = parseInt(data.updated,10);
    this._fullcount = parseInt(data._fullcount,10);
    this._ostcount = parseInt(data._ostcount,10);

    // Harakteristika:
    // HarakteristikaOsnovnogoKomponenta:
    // KlassifikaciyaModeli:
    // KodOsnovnogoKomponentaVnutrennii:
    // Kollekciya:
    // Konstrukciya:
    // Material: split
    // MinZakazEdinitsTovarov: parseInt
    // Nomenklatura: "Жилетка д/х Snowimage @5синій (110,116,122,128,134)"
    // Osobennost:
    // Pol:
    // RazmerGruppa:
    // RazmerHar:
    // RazmerPostavshikaHar:
    // SezonPrimeneniya:
    // SostavTkani: split
    // StranaIzgotovitel:
    // TGroup:
    // Uteplitel: split
    // VozrastnayaGruppa:
    // categoryId:
    // store: {}
    // _parent:
  };
  updateStore(item) {
    this.store[item.RazmerHar] = item.KolichestvoOstatok;
    this.linkSize[item.KodVnutrennii] = item.RazmerHar;
  };
  updateLocal() {};
  updateFromServer() {};
  // ---- Order product
  checkout() {};
}
