/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
const ua = {
  advertise: {
    day: 'День',
    days: 'Днів',
  },
  new_card: {
    'No sizes': 'Нажаль ростовок вже нема. Розміри, виділені кольором, можна придбати тут',
  },
  dropshipping: {
    Price: 'Прайс',
    'Load full price': 'Завантажити повний прайс',
    'Your request is under review': 'Ваш запит розглядається...',
    'Fill a form below to receive a full price list': 'Для отримання доступу до повного прайс-листу для всіх товарів відправте нам запит з наступною інформацією про себе:',
    'Sample price': 'Приклад прайс-листа',
    Name: 'Ім\'я',
    'Second name': 'По-батькові',
    'Family name': 'Прізвище',
    Phone: 'Номер телефону',
    Email: 'Електронна пошта',
    'Company name': 'Назва компанії',
    'Your websites': 'Ваші веб-ресурси (сайти, сторінки соціальних мереж і т.ін.)',
    'Additional information': 'Додатково про себе',
    'Send request': 'Надіслати запит',
    Dropshipping: 'Прайс-листи для дропшиперів',
    'Here you can download a sample price list, send request for full price list or get help':
      'Для дропшиперів ми надаємо можливість регулярно скачувати прайс-листи для розміщення у ваших інтернет-магазинах',
    'Here you can download a sample price list': 'Тут ви можете завантажити приклад нашого прайс-листа.',
    Download: 'Завантажити',
    'Get pricelist': 'Повний прайс-лист',
    'Your request was rejected': 'Ваш запит відхилено',
  },
  g: {
    Yes: 'Так',
    No: 'Ні',
    'Item comment': 'Коментар',
  },
  catalog: {
    All: 'Всі товари',
  },
  Filters: {
    Height: 'Зріст',
  },
  interface: {
    'Your cart is out of date. Refresh please':
      'Ваша корзина неочікувано змінилася. Можливо Ви працювали з іншого пристрою (наприклад, телефону). Обновіть сторінку для того, щоб побачити поточне замовлення на сервері.',
  },
  tutorial: {
    "Don't show more": 'Перейти до каталогу та не показувати більше цю сторінку',
    'Go to catalog': 'Перейти до каталогу',
  },
  navigation: {
    Back: 'Назад',
  },
  debug: {
    'Interface version': 'Версія інтерфейсу',
    Build: 'Збірка',
  },
  auth: {
    'For order you must be logged': 'Для того, щоб здійснювати замовлення та отримувати більше інформації увійдіть до системи',
    'Log In': 'Вхід',
    'Log In by Password': 'Вхід з паролем',
    'Send SMS': 'Відправити SMS з кодом',
    'Log In by SMS': 'Вхід через SMS',
    'Log out': 'Вихід',
    Register: 'Реєстрація',
    'Instruction to restore password was send to your email':
        'Інструкції для відновлення відправлені на електронну пошту. Перевірте Вашу поштову скриньку, перейдіть за посиланням, яке прийде у листі та введіть новий пароль.',
    'This phone number not registered': 'Такий номер не зареєстровано',
    'Phone or email': 'Телефон або пошта',
    Forgot: 'Нагадати',
    'Sign out?': 'Вийти?',
    'Show without prices': 'Перегляд без цін',
  },
  stock: {
    Size: 'Розмір',
    Price: 'Ціна',
    Buy: 'Купити',
  },
  loader: {
    'Loading...': 'Завантаження...',
  },
  CheckoutPage: {
    'Order list': 'Склад замовлення',
    'Payment and delivery': 'Доставка та сплата',
    Order: 'Замовлення',
    "You can't skip a step that isn't optional.": "You can't skip a step that isn't optional.",
    'Your order id is': 'Ваш номер замовлення',
    'We will connect you.': "Менеджер зв'яжеться з Вами для уточнення замовлення найближчим часом.",
    'Go back to list': 'Повернутися до каталогу',

  },
  orders: {

  },
  shoppingTable: {
    Product: 'Найменування',
    Qty: 'Кільк.',
    Cost: 'Вартість',
  },
  'MainMenu.BottomBlock': {
    Chat: 'Написати',
    Quick: 'Швидко',
    Call: 'Зателефонувати',
  },
  Checkout: {
    Finish: 'Відправити',
    'Phone number': 'Телефон',
    'Order for:': 'Замовлення для:',
  },
  Loadable: {
    'Loading...': 'Завантаження...',
    Error: 'Something wrong... Try again later.',
    stage_0: 'Профіль користувача',
    stage_1: 'Словники',
    stage_2: 'Товари',
    stage_3: 'Обробка',
  },
};

export default ua;
