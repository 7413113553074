/* eslint-disable prefer-template */
/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

import Helper from 'Helper';
import { fetch } from 'whatwg-fetch';

import Actions from 'new/actions';

import V5ProductModel from '../../v5/Models/ProductModel';
import ProductModel from '../../v4/models/Product';

export default class productsActions {
  static REMOTE_PRODUCTS_ADD = 'REMOTE_PRODUCTS_ADD';

  static SET_CURRENT_ITEM = 'SET_CURRENT_ITEM';

  static PRODUCT_LOAD_NEXT = 'PRODUCT_LOAD_NEXT';

  static REMOTE_PRODUCTS_APPEND = 'REMOTE_PRODUCTS_APPEND';

  static START_LOAD_NEXT = 'START_LOAD_NEXT';

  static SHOW_SEARCH_BAR = 'SHOW_SEARCH_BAR';

  static REMOTE_PRODUCT_SEARCH = 'REMOTE_PRODUCT_SEARCH';

  static REMOTE_PRODUCT_SEARCH_START = 'REMOTE_PRODUCT_SEARCH_START';

  static LIKE = 'LIKE';

  static DETAIL_PAGE = 'DETAIL_PAGE';

  static SET_BARCODE_MODE = 'SET_BARCODE_MODE';

  static BARCODE_VISIBLITY = 'BARCODE_VISIBLITY';

  static ACTIONS_PRODUCT_ALLOWTOSALE = 'ACTIONS_PRODUCT_ALLOWTOSALE';

  static createActionProductAllowToSale(productID) {
    return (dispatch, getState) => {
      const { allowToSale, apiRoot } = getState().api;
      const url = apiRoot + allowToSale + '/' + productID;
      fetch(url, {
        method: 'PUT',
        mode: 'cors', // no-cors, cors, *same-origin
        credentials: 'include', // include, same-origin, *omit
      })
        .then((resp) => resp.json())
        .then((data) => {
          dispatch({
            type: this.ACTIONS_PRODUCT_ALLOWTOSALE,
            payload: {
              ID: data.ID,
              AllowToSale: data.AllowToSale,
            },
          });
        });
    };
  }

  static createShowBarcodeScannerAction(mode) {
    return (dispatch, getState) => {
      dispatch(
        this.createShowSearchBarAction(true),
      );
      dispatch({
        type: this.BARCODE_VISIBLITY,
        payload: mode,
      });
    };
  }

  static toggleBarcodeMode(mode) {
    return {
      type: this.SET_BARCODE_MODE,
      payload: mode,
    };
  }

  static detailPage(refNum, history) {
    return (dispatch, getState) => {
      dispatch({
        type: 'DETAIL_PAGE',
        payload: refNum,
      });
      history.push(Helper.links.detailPage);
    };
  }

  static createLikeAction(refNum, state) {
    return (dispatch, getState) => {
      const { v3, apiRoot } = getState().api;
      const url = apiRoot + v3.getLike;
      fetch(url, {
        method: 'POST',
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, same-origin, *omit
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        redirect: 'follow', // manual, *follow, error

        body: JSON.stringify({
          refNum,
          state: !state,
        }),
      })
        .then((resp) => resp.json())
        .then((data) => {
          data.itemDetail = getState().products.items[refNum];
          data.itemImage = getState().products.images[refNum][0].md;

          dispatch({
            type: this.LIKE,
            payload: data,
          });
        })
        .catch((error) => {
          dispatch({
            type: 'LOG_FETCH',
            payload: {
              place: 'search',
              url,
              error,
            },
          });
        });
    };
  }

  static createSearchAction(text = '', full = '', match = false) {
    return (dispatch, getState) => {
      const { getProductsSearch, apiRoot } = getState().api;
      // var showSearchBar = getState().products.showSearchBar;
      const url = apiRoot + getProductsSearch;
      dispatch({
        type: this.REMOTE_PRODUCT_SEARCH_START,
      });
      fetch(url, {
        method: 'POST',
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, same-origin, *omit
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        redirect: 'follow', // manual, *follow, error

        body: JSON.stringify({
          searchValue: text,
          full,
          match: match ? '1' : '0',
        }),
      })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.products !== undefined) {
            this.normalize(data, getState());
            dispatch({
              type: this.REMOTE_PRODUCT_SEARCH,
              payload: data,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: 'LOG_FETCH',
            payload: {
              place: 'search',
              url,
              error,
            },
          });
        });
    };
  }

  static createShowSearchBarAction(value, search) {
    return (dispatch, getState) => {
      dispatch({
        type: this.SHOW_SEARCH_BAR,
        payload: value,
        searchValue: search,
      });
    };
  }

  static productLoadNext(page = 0, count = 10) {
    return (dispatch, getState) => {
      const { getProducts, apiRoot } = getState().api;
      const url = apiRoot + getProducts;

      if (getState().products.fetchStarted) return;
      dispatch({
        type: this.START_LOAD_NEXT,
      });
      // dispatch({
      //   type: Actions.fetch.FETCH_START
      // });
      const filters = getState().products.currentfilter;// currentGroup
      const cf = getState().catalogStructure.currentGroup;
      if (cf !== null) {
        filters.group = [].concat(filters.group, Object.keys(cf));
      }
      fetch(url, {
        method: 'POST',
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, same-origin, *omit
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        redirect: 'follow', // manual, *follow, error

        body: JSON.stringify({
          filters,
          start: getState().products.pageSize * (getState().products.currentPage + 1),
          pagesize: getState().products.pageSize,
        }), // body data type must match "Content-Type" header
      })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.products !== undefined) {
            this.normalize(data, getState());
            dispatch({
              type: this.REMOTE_PRODUCTS_APPEND,
              payload: data,
            });
          }
          // dispatch({
          //   type: Actions.fetch.FETCH_END
          // });
        })
        .catch((error) => {
          dispatch({
            type: 'LOG_FETCH',
            payload: {
              place: 'productLoadNext',
              url,
              error,
            },
          });
        });
      dispatch({
        type: this.PRODUCT_LOAD_NEXT,
        payload: count,
      });
    };
  }

  static createChangeCurrentItemAction(currentItem, index) {
    //        if(index===0) {
    //            currentItem--;
    //        }
    //        if(index===2) {
    //            currentItem++;
    //        }

    return {
      type: this.SET_CURRENT_ITEM,
      //                index: currentItem
      index,
    };
  }

  static createLoadProductsAction() {
    return (dispatch, getState) => {
      const { apiRoot, getItems } = getState().api;

      const url = apiRoot + getItems;
      const filters = getState().products.currentfilter;
      filters.allowSalePacks = Helper.config.allowSalePacks;

      const cf = getState().catalogStructure.currentGroup;
      if (cf !== null && filters.group.length === 0) {
        filters.group = [].concat(filters.group, Object.keys(cf));
      }
      fetch(url, {
        method: 'POST',
        mode: 'cors', // no-cors, cors, *same-origin
        credentials: 'include', // include, same-origin, *omit
        body: JSON.stringify({
          filters,
          start: 0,
          pagesize: getState().products.pageSize,
          cart: getState().cart.items,
          minOrder: getState().cart.minOrder,
        }),
      })
        .then((resp) => resp.json())
        .then((data) => {
          const models = {};
          if (data.products !== undefined) {
            this.parseProducts(data, models);
            this.normalize(data, getState());
            dispatch({
              type: this.REMOTE_PRODUCTS_ADD,
              payload: data,
              products: models,
            });
          }
          if (getState().fetch.initialLoading) {
            dispatch(Actions.fetch.createInitialFetchAction(
              'initialLoadingStage',
              3,
            ));
            dispatch(Actions.catalog.filterCategories());
            dispatch(Actions.fetch.createInitialFetchAction(
              'initialLoading',
              false,
            ));
          }
          dispatch(Actions.cart.recalculateCartCost());
          window.scrollTo(0, 1);
          // console.log("WWRT scroll zerro",Helper.positionY);
        })
        .catch((error) => {
          dispatch({
            type: 'LOG_FETCH',
            payload: {
              place: 'createLoadProductsAction',
              url,
              error,
            },
          });
        });
    };
  }

  static parseProducts(data, models) {
    Object.keys(data.products).map((v) => {
      const tmp = new ProductModel();
      tmp.createFromJSON(data.products[v]);
      models[tmp.RefNum] = tmp;
      return null;
    });
    Object.keys(data.ost).map((v) => {
      const parent = data.ost[v]._parent;
      if (models[parent] !== undefined) {
        // var size = data.ost[v].RazmerHar;
        models[parent].updateStore(data.ost[v]);
      } else {
        console.log('Parent undefined', parent, v);
      }
      return null;
    });
  }

  static normalize(data, state) {
    if (data.ost !== undefined) {
      this.normalizeProducts(data.ost);
    }
    if (data.products !== undefined) {
      this.normalizeProducts(data.products);
      if (data.ost !== undefined) {
        this.additionalProductsData(data.products, data.ost);
      } else {
        Helper.logError('No ost');
      }
      if (data.images !== undefined) {
        this.imagesProductsData(data.products, data.images, state.api);
      } else {
        Helper.logError('No images');
      }
    }
    if (data.cartItems !== undefined) {
      this.normalizeProducts(data.cartItems);
    }
  }

  static imagesProductsData(products, images, api) {
    // Sizes
    const keys = Object.keys(images);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const refNum = images[key].KodVnutrennii;
      if (products[refNum] !== undefined) {
        let src = null;
        const v = images[key];
        if (v.md === null) {
          src = api.imgRoot + 'max/' + encodeURI(v.imageURL).replace(/%5C/g, '/');
        } else {
          src = api.minImgRoot + v.md + '.jpg';
        }
        v.height = parseInt(v.height, 10);
        v.width = parseInt(v.width, 10);
        products[refNum].imagesList.push({
          imageURL: src,
          height: v.height,
          width: v.width,
          aspect: v.height > 0 ? v.width / v.height : 1,
          altTag: v.altTag,
        });
      } else {

      }
    }
  }

  static additionalProductsData(products, ost) {
    // Sizes
    const keys = Object.keys(ost);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const parent = ost[key]._parent;
      const kol = ost[key].KolichestvoOstatok;
      if (kol > 0) {
        if (products[parent] !== undefined && ost[key].updated > 1) {
          products[parent].store[ost[key].RazmerHar] = kol;
        } else {
          // Helper.logError(parent,ost[key]);
        }
      }
    }
  }

  static normalizeProducts(products) {
    const keys = Object.keys(products);
    keys.map((k) => {
      products[k].KodVnutrennii = parseInt(products[k].KodVnutrennii, 10);
      products[k].EtoNabor = parseInt(products[k].EtoNabor, 10);
      products[k].KolichestvoVNabore = parseInt(
        products[k].KolichestvoVNabore,
        10,
      );
      products[k].KolichestvoOstatok = parseInt(
        products[k].KolichestvoOstatok,
        10,
      );
      products[k].Cena = parseInt(products[k].Cena, 10);
      products[k].CenaStaraya = parseInt(products[k].CenaStaraya, 10);
      products[k].AllowToSale = parseInt(products[k].AllowToSale, 10);
      if (
        products[k].MinZakaz === undefined
        || products[k].MinZakaz === null
        || products[k].MinZakaz === ''
      ) {
        products[k].MinZakaz = products[k].KolichestvoVNabore;
      } else {
        products[k].MinZakaz = parseInt(products[k].MinZakaz, 10);
        if (products[k].MinZakaz === 0) {
          products[k].MinZakaz = products[k].KolichestvoVNabore;
        }
      }
      products[k].Novinka = parseInt(products[k].Novinka, 10);
      products[k]._fullcount = parseInt(products[k]._fullcount, 10);
      products[k]._ostcount = parseInt(products[k]._ostcount, 10);
      products[k]._parent = parseInt(products[k]._parent, 10);
      products[k].imagesCount = parseInt(products[k].imagesCount, 10);
      products[k].PodZakaz = parseInt(products[k].PodZakaz, 10);
      if (products[k].EtoNabor === 1) {
        products[k].store = {};
        products[k].imagesList = [];
      } else if (products[k].sizes === null) products[k].sizes = products[k].RazmerHar;
      if (products[k].sizes !== undefined) {
        if (products[k].sizes_array === undefined
          || products[k].sizes_array.length === 0) {
          products[k].sizes_array = products[k].sizes.split(/,/g);
        } else if (products[k].sizes_array !== undefined && products[k].sizes_array[0] === '') {
          products[k].sizes_array = [products[k].sizes];
        }
        products[k].sizes_array.sort((a, b) => {
          if (parseInt(a, 10) < parseInt(b, 10)) {
            return -1;
          }
          if (parseInt(a, 10) > parseInt(b, 10)) {
            return 1;
          }
          return 0;
        });
      }

      // let prd = new V5ProductModel();
      // prd.copyFrom(products[k]);
      // products[k] = prd;
      // console.log("WRD",prd);
      return null;
    });
  }
}
