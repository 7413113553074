import React from "react";

import Helper from "Helper";

class AdvancedComponent extends React.Component {
  static whyDidYouRender = true
  constructor(props) {
    super(props);
    if(Helper.debug.allowShowInternalConstruct) {
      Helper.statLog("Construct Component: " + this.constructor.name);
    }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    Helper.logError(error, errorInfo);
  }

  render() {
    if(Helper.config.allowShowInternalRenders) {
      Helper.statLog("Render Component: " + this.constructor.name);
    }
    return null;
  }
}

export default AdvancedComponent;
