/* eslint-disable prefer-template,no-underscore-dangle */
import React from 'react';
import ua from './i18n/ua';

import logErrors from './v4/core/logger/logError';
import isLocal from './v4/core/functions/isLocal';
import { mapDispatchToProps, mapAuthDispatchToProps } from './v4/core/functions/redux/mapDispatchToProps';
import localHosts from './v4/variables/localHosts';
import routes from './v4/variables/routes';
import debugConfigs from './v4/configs/debug';
import Logger from './v5/Core/Logger';

export default class Helper {
  static allowToSaleItem = (item, cart, ones) => {
    const refNum = item.KodVnutrennii;
    const result = {
      refNum: item.KodVnutrennii,
      //  Упаковок всего
      pack: item.KolichestvoOstatok - item.reserved,
      items: {},
      // Единичных остатков по каждому
      ones: {},
      // Единичных остатков всего
      onesFull: 0,
      allowPartialPack: false,
    };
    // console.log(item);
    result.allowPartialPack = Helper.config.allowMinimalOrder && (
      item.MinZakaz > 1
      && item.MinZakaz < item.KolichestvoVNabore
      && ones.length === 1
    );

    // Проводим расчет по упаковкам
    // Удаляем собственные резервы из доступного количества
    if (cart.reserved[refNum]) result.pack += cart.reserved[refNum];
    // Расчитываем доступное количество для каждого элемента из набора
    const currentCountItemsInPacks = result.pack * item.KolichestvoVNabore;
    // Сохраняем полное количество единиц по каждому товару из набора (без учета остатков)
    result.onesFull = currentCountItemsInPacks;
    // Проходимся по всем единичным остаткам и добавляем их в доступные
    ones.map((subItem) => {
      if (!subItem) {
        // result.ones[subRefNum]
        return null;
      }
      const subRefNum = subItem.KodVnutrennii;
      // Сначала убираем из доступного то, что зарезервировано уже ранее
      result.onesFull += subItem.KolichestvoOstatok - subItem.reserved;
      if (cart.reserved[subRefNum]) result.onesFull -= cart.reserved[subRefNum];

      // Инициализируем
      if (result.items[subRefNum] === undefined) result.items[subRefNum] = currentCountItemsInPacks;
      if (result.ones[subRefNum] === undefined) {
        result.ones[subRefNum] = 0;
        if (result.allowPartialPack) {
          result.ones[subRefNum] = currentCountItemsInPacks;
        }
      }
      // Добавляем доступное количество остатков без зарезервированных
      result.ones[subRefNum] += subItem.KolichestvoOstatok - subItem.reserved;
      result.items[subRefNum] += subItem.KolichestvoOstatok - subItem.reserved;
      // Убираем то, что находится в корзине
      if (cart.items[subRefNum] !== undefined) {
        result.onesFull -= cart.items[subRefNum];
        result.items[subRefNum] -= cart.items[subRefNum];
        result.ones[subRefNum] -= cart.items[subRefNum];
      }
      if (cart.minOrder[subRefNum] !== undefined) {
        result.onesFull -= cart.minOrder[subRefNum] * item.MinZakaz;
        result.items[subRefNum] -= cart.minOrder[subRefNum] * item.MinZakaz;
        result.ones[subRefNum] -= cart.minOrder[subRefNum] * item.MinZakaz;
      }
      // Так как в корзине находятся уже зарезервированные товары, то не нужно учитывать их дважды
      if (cart.reserved[subRefNum]) {
        result.onesFull += cart.reserved[subRefNum];
        result.items[subRefNum] += cart.reserved[subRefNum];
        result.ones[subRefNum] += cart.reserved[subRefNum];
      }
      return null;
    });
    const virtual = cart.virtualItems.asPlainObject();
    Object.keys(virtual).map((subItem) => {
      if (result.ones[subItem]) result.ones[subItem] -= virtual[subItem];
      if (result.ones[subItem] < 0) result.ones[subItem] = 0;
    });
    // Вычитаем упаковки
    if (cart.items[item.KodVnutrennii] !== undefined) {
      result.pack -= cart.items[item.KodVnutrennii];
      result.onesFull -= cart.items[item.KodVnutrennii] * item.KolichestvoVNabore;
      ones.map((subItem) => {
        result.items[subItem.KodVnutrennii] -= cart.items[item.KodVnutrennii] * item.KolichestvoVNabore;
        if (result.allowPartialPack) {
          result.ones[subItem.KodVnutrennii] -= cart.items[item.KodVnutrennii] * item.KolichestvoVNabore;
        }
        return null;
      });
    }
    if (Object.keys(result.items).length > 0) {
      let min = currentCountItemsInPacks;
      Object.keys(result.items).map((rNum) => {
        if (min > result.items[rNum]) min = result.items[rNum];
        return null;
      });
      result.pack = Math.trunc(min / item.KolichestvoVNabore);
    }
    // console.log("WREST", result, cart);
    return result;
  };

  static debug = debugConfigs;

  // static debug = {
  //   allowShowReduxDevTools: false,
  //   allowShowReduxLogger: true,
  //   allowShowReduxLoggerConfig: {
  //     duration: true,
  //     diff: true,
  //     collapsed: true,
  //   },
  //   allowShowRenders: false,
  //   allowShowInternalRenders: false,
  //   allowShowInternalConstruct: false,
  //   restrictFromConstruct: [],
  //   allowHtml5Routing: true,
  // };

  static allowBuy(noAuth, logged) {
    // console.log("WEST",noAuth,logged);
    if (Helper.config.allowUnauthorized || logged) return true;
    return !noAuth;
  }

  static checks = {
    phone: (phone) => {
      if (
        phone === null
        || phone === undefined
        // eslint-disable-next-line no-restricted-globals
        || isNaN(phone)
      ) return false;
      return !(phone === null || phone.length !== 13 || phone[0] !== '+' || phone.replace(/[^+0-9]/g, '') !== phone);
    },
    notEmptyString: (text) => !Helper.checks.emptyString(text),
    emptyString: (text) => typeof text !== 'string' || false || text === false || text === undefined || text.length === 0,
    moreThanZero: (v) => v > 0,
    email: (text) => !(typeof text !== 'string'
        // eslint-disable-next-line no-useless-escape
        || !text.match(/[a-z0-9-.]+@[a-z0-9-.]+\.[a-z0-9-.]+/i)),
    empty: (text) => typeof text !== 'string' || false || text === false || text === undefined || text.length === 0,
  };

  static innerHeight = window.innerHeight;

  static allowOrderPackMore = (item, cart, linkedItems) => {
    const refNum = item.KodVnutrennii;
    return (
      item._fullcount
        - (cart.minOrder[linkedItems[0]] === undefined
          ? 0
          : cart.minOrder[linkedItems[0]] + 1)
          * item.MinZakaz
        - (cart.items[linkedItems[0]] === undefined
          ? 0
          : cart.items[linkedItems[0]])
        - (cart.items[refNum] === undefined ? 0 : cart.items[refNum])
          * item.KolichestvoVNabore
      >= item.KolichestvoVNabore
    );
  };

  static allowOrderMinMore = (item, cart, linkedItems) => {
    const refNum = item.KodVnutrennii;
    return (
      item._fullcount
        - (cart.items[refNum] === undefined ? 0 : cart.items[refNum])
          * item.KolichestvoVNabore
        - (cart.items[linkedItems[0]] === undefined
          ? 0
          : cart.items[linkedItems[0]])
        - (cart.minOrder[linkedItems[0]] === undefined
          ? 1
          : cart.minOrder[linkedItems[0]])
          * item.MinZakaz
      >= item.MinZakaz
    );
  };

  static correctPhoneNum(tel) {
    let ctel = tel;
    if (ctel.length > 0) {
      if (ctel.length === 1 && ctel !== '+') {
        ctel = '+380' + ctel;
      }
      if (ctel.substr(0, 1) !== '+') {
        while (ctel.length < 10) ctel = '0' + ctel;
        ctel = '+380' + ctel;
      }
      ctel = ctel.trim();
      // eslint-disable-next-line no-useless-escape
      ctel = ctel.replace(/[^+0-9]/gi, '');
      ctel = ctel.replace(/\+38\+380/gi, '+380');
      ctel = ctel.replace(/\+3800/gi, '+380');
    }
    return ctel;
  }

  static copyToClipboard = (text) => {
    if (
      navigator !== undefined
      && navigator.clipboard !== undefined
      && navigator.clipboard.writeText !== undefined
    ) {
      navigator.clipboard.writeText(text);
    }
    // Create new element
    const el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = text;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    // el.style = {position: 'absolute', left: '-9999px'};
    el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
    return false;
  };

  static interface = {
    elementsInRow: 1,
  };

  static positionY = 0;

  static configurableSettings =[
    // 'allowShowNewBanner',
    // 'allowShowAdvertise',
  ];

  static config = {
    allowSalePacks: false,
    allowMinimalOrder: false,
    basicCurrency: 'UAH',
    literalCurrency: false,
    allowUserChangeCurrency: true,
    allowCents: true,
    minimalOrderSumm: 0,
    showStartInstructions: false,
    allowShowNewBanner: true,
    allowShowSaleBanner: true,
    allowLikes: true,
    buyButton: 0,
    allowShowAdvertise: false,
    allowShareFilters: true,
    advertImage: false,
    allowUnauthorized: false,
    allowShowFirstPageForUnauthorized: false,
    allowSortArea: false,
    logo: '',
    allowLocalStatLogConsole: false,
    allowMobileHeaderLogo: false,
    simpleCheckout: true,
    backgroundImageHome: null,
    allowShowCatalog: false,
    catalogDrawerAnchor: 'left',
    allowSecondToolbar: false,
    allowTopSearchButton: true,
    allowTopFilterButton: true,
    categoryMode: false,
    showSizesFilter: true,
    advancedCart: true,
    defaultDeliveryType: 0,
    defaultPaymentType: 0,
    allowShowAdminLinkInProfileMenu: false,
    allowShowVersionInProfileMenu: false,
    allowActivityCheck: false,
    allowShowBreadcrumbs: false,
    checkoutForm: 'advanced',
    allowRepay: false,
    costMultiplicate: 0,
    allowShowSettingsDrawer: false,
    allowCartItemsComments: false,
    allowLabelBeforeCart: false,
    allowRealtimeReserve: false,
    allowDisableItemsAction: false,
    alowFilterInDrawer: false,
    pricelistAccessRequestInProgress: false,
    alowLoadPricelist: false,
    allowRRP: false,
    googleAnalyticsID: null,
    allowRightClick: false,
    allowOrderOnes: true,
    allowDropshipping: false,
    allowShowStockOnCard: false,
    allowShares: true,
    allowRedirectButton: false,
    allowAutomaticReserve: false,
    allowShowDescription: false,
    allowEditDescription: false,
    allowBarcode: false,
    allowShowOnesQuantity: true,
    allowOrderOnesSubblock: false,
    showBigSaleText: true,
    showBigSaleTextMinPercent: 15,

    allowedCommentGroups: [
      'гольфи',
      'колготки',
      'кепка',
      'трусики',
      'шкарпетки',
      'блуза',
    ],
    showArtikulListOnRefNumClick: false,

  };

  static currencies = {
    UAH: {
      long: 'UAH',
      short: String.fromCharCode(0x20b4),
      national: 'грн',
    },
    USD: {
      long: 'USD',
      short: '$',
      national: '$',
    },
  };

  static currentItem = 0;

  static translations = ua;

  static consoleRows = [];

  static tr(item, domain = 'global', params) {
    let text = item;
    if (
      Helper.translations[domain] !== undefined
      && Helper.translations[domain][item] !== undefined
    ) {
      text = Helper.translations[domain][item];
    }
    if (params !== undefined) {
      Object.keys(params).map((v) => {
        text = text.replace('%' + v + '%', params[v]);
        return null;
      });
    }
    return text;
  }

  static isRestricted(path) {
    let restricted = true;
    switch (path) {
      case Helper.links.home:
      case Helper.links.loginPage:
      case Helper.links.logoutPage:
      case Helper.links.registerPage:
      case Helper.links.restorePasswordPage:
      case Helper.links.forbidden:
        restricted = false;
        break;
      default:
    }
    return restricted;
  }

  static links = routes;

  static branding = {
    short: 'N&L',
  };

  static phonesMap = '+380671112233';

  // static phoneCheckMask = /^\+([\d]+)$/i;
  static phoneCheckMask = /^\+\d{12}$/i;

  static preventSelection = false;

  static initialize() {
    const host = window.location.host.split(':')[0];
    if (
      host !== '192.168.0.4'
      && host !== 'localhost'
      && host !== 'testopt.localhost'
      && host !== 'kid.localhost'
      && host !== 'drop.opt.moda'
      && host !== 'kid.ua.moda'
      && host !== 'opt.moda'
      // && !host.match(/.*localhost(|.*)/)
    ) {
      // window.addEventListener('beforeunload', Helper.wOnbeforeunload);
      document.addEventListener('contextmenu', (event) => event.preventDefault());
    }
    // Helper.checkInactive()
    // window.onpopstate = this.wOnPopState
  }

  static getElementsInRow() {
    const w = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0,
    );
    if (w <= 500) {
      // xs
      Helper.interface.elementsInRow = 1;
    } else if (w <= 1024) {
      // sm
      Helper.interface.elementsInRow = 2;
    } else if (w <= 1280) {
      // md
      Helper.interface.elementsInRow = 3;
    } else if (w <= 1440) {
      // md
      Helper.interface.elementsInRow = 4;
    } else if (w <= 1920) {
      // lg
      Helper.interface.elementsInRow = 5;
    } else {
      // xl
      Helper.interface.elementsInRow = 6;
    }
    return Helper.interface.elementsInRow;
  }

  static wOnbeforeunload(e) {
    e.preventDefault();
    e.returnValue = 'alert';
    return 'alert';
  }

  static wOnPopState(e) {
    e.preventDefault();
    e.returnValue = 'alert';
    return 'alert';
  }

  static goToAuth() {
    // window.removeEventListener('beforeunload', Helper.wOnbeforeunload);
    window.location.href = Helper.links.loginPage;
    return false;
  }

  static reload() {
    // window.removeEventListener('beforeunload', Helper.wOnbeforeunload);
    window.location.reload();
  }

  static addHandler(element, event, handler) {
    if (element.attachEvent !== undefined) element.attachEvent('on' + event, handler);
    else if (element.addEventListener) element.addEventListener(event, handler, false);
  }

  static removeSelection() {
    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    } else if (document.selection && document.selection.clear) document.selection.clear();
  }

  static killCtrlA(e) {
    const event = e || window.event;
    const sender = event.target || event.srcElement;

    if (sender.tagName.match(/INPUT|TEXTAREA/i)) return;

    const key = event.keyCode || event.which;
    if (event.ctrlKey && key === 'A'.charCodeAt(0)) {
      // 'A'.charCodeAt(0) можно заменить на 65
      Helper.removeSelection();

      if (event.preventDefault) event.preventDefault();
      else event.returnValue = false;
    }
  }

  static localRun() {
    return !!(window.location.hostname === 'localhost'
      || window.location.hostname.match(/192\.168\..*/)
      || window.location.hostname.match(/localhost/));
  }

  static noSelection(element) {
    if (!Helper.localRun()) {
      // не даем выделять текст мышкой
      this.addHandler(element, 'mousemove', () => {
        if (this.preventSelection) Helper.removeSelection();
      });
      this.addHandler(element, 'mousedown', (e) => {
        const event = e || window.event;
        const sender = event.target || event.srcElement;
        Helper.preventSelection = !sender.tagName.match(/INPUT|TEXTAREA/i);
      });

      // борем dblclick
      // если вешать функцию не на событие dblclick, можно избежать
      // временное выделение текста в некоторых браузерах
      this.addHandler(element, 'mouseup', () => {
        if (this.preventSelection) Helper.removeSelection();
        Helper.preventSelection = false;
      });

      // борем ctrl+A
      // скорей всего это и не надо, к тому же есть подозрение
      // что в случае все же такой необходимости функцию нужно
      // вешать один раз и на document, а не на элемент
      Helper.addHandler(element, 'keydown', this.killCtrlA);
      Helper.addHandler(element, 'keyup', this.killCtrlA);
    } else {
      Helper.localLog('Local Run');
    }
  }

  static logError = logErrors;

  static localLog = logErrors;

  static logDispatch(dispatch, action, file) {
    try {
      dispatch(action);
    } catch (error) {
      Logger.error('Core.redux.dispatch', {
        type: 'LOG_DISPATCH',
        payload: {
          place: file,
          error,
          action,
        },
      });
      dispatch({
        type: 'LOG_DISPATCH',
        payload: {
          place: file,
          error,
          action,
        },
      });
    }
  }

  static allowMinOrder(item) {
    if (item === undefined) {
      return false;
    }
    return item.MinZakaz !== item.KolichestvoVNabore && item.MinZakaz > 1;
  }

  static renderSizes(itemSizes) {
    if (itemSizes === null || itemSizes === undefined) {
      return '';
    }

    const itemSizesArray = itemSizes.split(',');
    let newItemSizes = [];
    if (itemSizesArray.length > 6) {
      newItemSizes.push(<span>{itemSizesArray.slice(0, 6).join(',')}</span>);
      for (let i = 6, j = itemSizesArray.length; i < j; i += 6) {
        newItemSizes.push(
          <>
            <br />
            <span>{itemSizesArray.slice(i, i + 6).join(',')}</span>
          </>,
        );
      }
    } else {
      newItemSizes = itemSizes;
    }
    return newItemSizes;
  }

  static statistic = {};

  static statLog(className) {
    Helper.statistic[className] = Helper.statistic[className] === undefined
      ? 1
      : Helper.statistic[className] + 1;
    // eslint-disable-next-line no-console
    if (Helper.debug.allowShowInternalRenders) console.log(className);
    // Helper.showStat();
  }

  static showStat() {
    // console.log(Helper.statistic)
  }

  static setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }

  static getCookie(cname) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  static checkCookie(name) {
    return Helper.getCookie(name) !== '';
  }

  static userActivity = {
    now_no_active: 0,
    // Количество секунд простоя мыши, при котором пользователь считается неактивным
    no_active_delay: 60 * 10,
    activityInterval: null,
    updadteInterval: null,
  };

  /**
   *  Source: https://myrusakov.ru/javascript-noactive-user.html
   */
  static checkInactive() {
    function activeUser() {
      Helper.userActivity.now_no_active = 0; // Обнуляем счётчик простоя секунд
    }
    function noActiveUser() {
      Helper.userActivity.now_no_active += 1;
    }
    function updateChat() {
      if (Helper.userActivity.now_no_active >= Helper.userActivity.no_active_delay) {
        // Проверяем не превышен ли "предел активности" пользователя

      }
    }
    if (Helper.userActivity.activityInterval === null) {
      // Каждую секунду увеличиваем количество секунд простоя мыши
      Helper.userActivity.activityInterval = setInterval(noActiveUser, 1000);
    }
    if (Helper.userActivity.updadteInterval === null) {
      // Запускаем функцию updateChat() через определённый интервал
      Helper.userActivity.updadteInterval = setInterval(updateChat, 1000);
    }
    document.onmousemove = activeUser; // Ставим обработчик на движение курсора мыши
  }

  static getRootApi() {
    return isLocal()
      ? localHosts[window.location.hostname].apiRoot
      : '/';
  }

  // static getCookie(name) {
  //   let matches = document.cookie.match(new RegExp(
  //     "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  //   ));
  //   return matches ? decodeURIComponent(matches[1]) : undefined;
  // }

  static sendEvent(id, type, text) {
    if (navigator.sendBeacon) {
      const url = Helper.getRootApi() + 'api/logger/events';
      const data = new FormData();
      data.append('userID', id);
      data.append('type', type);
      data.append('text', JSON.stringify(text));
      data.append('time', new Date().getTime().toLocaleString());
      data.append('url', window.location.href);

      // let jsonData = {
      //   userID: id,
      //   type: type,
      //   text: text,
      //   time: new Date().getTime().toLocaleString(),
      //   url: window.location.href
      // };
      //
      // let senddata = JSON.stringify(jsonData);
      navigator.sendBeacon(url, data);
    }
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.warn('Send Event:', id, type, text);
    }
  }

  static sendLog(text, error) {
    if (navigator.sendBeacon) {
      const url = Helper.getRootApi();
      const data = new FormData();
      const date = new Date();
      data.append('text', text);
      data.append('error', JSON.stringify(error));
      data.append('time', date.getTime().toLocaleString());
      data.append('page', window.location.href);
      navigator.sendBeacon(url, data);
    }
  }

  static sendError(text, error) {
    // eslint-disable-next-line no-console
    console.log(text, error);
  }

  static mapDispatchToProps = mapDispatchToProps

  static mapAuthDispatchToProps = mapAuthDispatchToProps

  static extractHostname(url) {
    let hostname;
    // find & remove protocol (http, ftp, etc.) and get hostname
    if (url.indexOf('//') > -1) {
      hostname = url.split('/')[2];
    } else {
      hostname = url.split('/')[0];
    }
    // find & remove port number
    hostname = hostname.split(':')[0];
    // find & remove "?"
    hostname = hostname.split('?')[0];
    return hostname;
  }

  static getShareText(name, sizesArray, price, priceOld, osoblyvist, Artikul = '', brand = '') {
    const currency = Helper.currencies[Helper.config.basicCurrency].national;
    const osob = osoblyvist ? `${osoblyvist}\n` : '';
    const ArtikulText = Artikul.split('@')[0];
    if (price == priceOld) {
      return `\n${ArtikulText} ${brand}\n${name}\n${osob}р-р: ${sizesArray.join(', ')}\nЦіна: ${(price / 100).toFixed(2)} ${currency}/шт.\n`;
    }
    return `\n${ArtikulText} ${brand}\n${name}\n${osob}р-р: ${sizesArray.join(', ')}\nСтара ціна: ${(priceOld / 100).toFixed(2)} ${currency}/шт.\nНова ціна: ${(price / 100).toFixed(2)} ${currency}/шт.\n`;
  }

  static getArtikul(Artikul) {
    const tmp = Artikul.split('@');
    if (tmp.length > 0) return tmp[0];
    return '';
  }
}
