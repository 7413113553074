/** Импортируем полифилы для реакта * */
import 'core-js/stable';
// import 'core-js/features/promise';
// import '@babel/polyfill';
// import 'react-app-polyfill/ie9';
// import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
// import { App } from './v4/App';

import registerServiceWorker, { unregister } from './registerServiceWorker';
import initPush from './v4/core/functions/initPush';

/** Классы для компонентов */
import './theme.css';
import './theme.desktop.css';
import './theme.mobile.css';
import './v4/sass/main.scss';
import Helper from './Helper';
/** ****** V5 ************** */
import ModuleRegistry from './v5/ModuleRegistry';
import ProfileDefinition from './v5/Modules/Profile';
import LoadingOverlay from './v3/Loadable/LoadingOverlay';
import ProductsFabric from './v5/Core/Products/ProductsFabric';
import productCreator from './VP/Creators/productCreator';
/** ****** V7 ************** */
ProductsFabric.create = productCreator;

const App = React.lazy(() => import('./v4/App'));
const App8 = React.lazy(() => import('./v8/App'));

axios.defaults.withCredentials = true;
ModuleRegistry.registerModule(new ProfileDefinition());
ModuleRegistry.init();

const render = () => {
  try {
    if (Helper.debug.allowV8) {
      ReactDOM.render(<React.Suspense fallback={<LoadingOverlay text="Load app..." noopacity={false} />}><App8 /></React.Suspense>, document.getElementById('root'));
    } else {
      ReactDOM.render(<React.Suspense fallback={<LoadingOverlay text="Load app..." noopacity={false} />}><App /></React.Suspense>, document.getElementById('root'));
    }
  } catch (e) {
    Helper.sendError('ERR:', e);
  }
};

render();
initPush();

unregister();
registerServiceWorker();
// setTimeout(() => {
//   navigator.serviceWorker.getRegistration().then((reg) => {
//     if (reg) {
//       reg.showNotification(
//         'Изменения',
//         {
//           body: 'В системе появились новые изменения. Обновите страницу, пожалуйста.',
//           icon: '/images/logo.png',
//           badge: '/images/logo.png',
//           requireInteraction: true,
//         },
//       );
//     } else {
//       console.log('Not registration');
//     }
//   });
// }, 1000);

if (process.env.NODE_ENV === 'development') {
// Webpack Hot Module Replacement API
  if (module.hot) {
    module.hot.accept('./v4/App', () => {
      render();
    });
    module.hot.accept('./v8/App', () => {
      render();
    });
  }
}
